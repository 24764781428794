import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// CountryFlagDropdown.tsx
import React from 'react';
import { MenuItem, Stack } from '@mui/material';
import { RHFSelect } from '@raas/shared/src/components/minimals/hook-form';
// prettier-ignore
import { AC, AD, AE, AF, AG, AI, AL, AM, AO, AQ, AR, AS, AT, AU, AW, AX, AZ, BA, BB, BD, BE, BF, BG, BH, BI, BJ, BL, BM, BN, BO, BQ, BR, BS, BT, BV, BW, BY, BZ, CA, CC, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CU, CV, CW, CX, CY, CZ, DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, EU, FI, FJ, FK, FM, FO, FR, GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY, HK, HM, HN, HR, HT, HU, IC, ID, IE, IL, IM, IN, IO, IQ, IR, IS, IT, JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ, LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, MK, ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ, NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM, PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY, QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN, SO, SR, SS, ST, SV, SX, SY, SZ, TA, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN, TO, TR, TT, TV, TW, TZ, UA, UG, UM, UY, UZ, VA, VC, VE, VG, VI, VN, VU, WF, WS, XK, YE, YT, ZA, ZM, ZW } from 'country-flag-icons/react/3x2';
import { countries } from './CountriesArray';
import { UneceFlag } from './UNFlag';
import { USFlag } from './USFlag';
var CountryFlagDropdown = function (_a) {
    var name = _a.name, label = _a.label, disabled = _a.disabled, sx = _a.sx;
    return (_jsx(RHFSelect, { name: name, label: label, InputLabelProps: { shrink: true }, disabled: disabled, sx: sx, children: countries.map(function (country) { return (_jsx(MenuItem, { value: country.code, children: _jsxs(Stack, { direction: "row", alignItems: "center", spacing: 1, children: [getCountryFlag(country.code, { width: '30px', height: 'auto', marginRight: '4px' }), country.label] }) }, country.code)); }) }));
};
export var getCountryFlag = function (code, styles, flagsWithoutWrapper) {
    switch (code) {
        case 'UN':
            return flagsWithoutWrapper ? (_jsx("div", { style: { height: '75px' }, children: _jsx(UneceFlag, { styles: styles }) })) : (_jsx(UneceFlag, { styles: styles }));
        case 'AC':
            return _jsx(AC, { style: styles });
        case 'AD':
            return _jsx(AD, { style: styles });
        case 'AE':
            return _jsx(AE, { style: styles });
        case 'AF':
            return _jsx(AF, { style: styles });
        case 'AG':
            return _jsx(AG, { style: styles });
        case 'AI':
            return _jsx(AI, { style: styles });
        case 'AL':
            return _jsx(AL, { style: styles });
        case 'AM':
            return _jsx(AM, { style: styles });
        case 'AO':
            return _jsx(AO, { style: styles });
        case 'AQ':
            return _jsx(AQ, { style: styles });
        case 'AR':
            return _jsx(AR, { style: styles });
        case 'AS':
            return _jsx(AS, { style: styles });
        case 'AT':
            return _jsx(AT, { style: styles });
        case 'AU':
            return _jsx(AU, { style: styles });
        case 'AW':
            return _jsx(AW, { style: styles });
        case 'AX':
            return _jsx(AX, { style: styles });
        case 'AZ':
            return _jsx(AZ, { style: styles });
        case 'BA':
            return _jsx(BA, { style: styles });
        case 'BB':
            return _jsx(BB, { style: styles });
        case 'BD':
            return _jsx(BD, { style: styles });
        case 'BE':
            return _jsx(BE, { style: styles });
        case 'BF':
            return _jsx(BF, { style: styles });
        case 'BG':
            return _jsx(BG, { style: styles });
        case 'BH':
            return _jsx(BH, { style: styles });
        case 'BI':
            return _jsx(BI, { style: styles });
        case 'BJ':
            return _jsx(BJ, { style: styles });
        case 'BL':
            return _jsx(BL, { style: styles });
        case 'BM':
            return _jsx(BM, { style: styles });
        case 'BN':
            return _jsx(BN, { style: styles });
        case 'BO':
            return _jsx(BO, { style: styles });
        case 'BQ':
            return _jsx(BQ, { style: styles });
        case 'BR':
            return _jsx(BR, { style: styles });
        case 'BS':
            return _jsx(BS, { style: styles });
        case 'BT':
            return _jsx(BT, { style: styles });
        case 'BV':
            return _jsx(BV, { style: styles });
        case 'BW':
            return _jsx(BW, { style: styles });
        case 'BY':
            return _jsx(BY, { style: styles });
        case 'BZ':
            return _jsx(BZ, { style: styles });
        case 'CA':
            return _jsx(CA, { style: styles });
        case 'CC':
            return _jsx(CC, { style: styles });
        case 'CD':
            return _jsx(CD, { style: styles });
        case 'CF':
            return _jsx(CF, { style: styles });
        case 'CG':
            return _jsx(CG, { style: styles });
        case 'CH':
            return _jsx(CH, { style: styles });
        case 'CI':
            return _jsx(CI, { style: styles });
        case 'CK':
            return _jsx(CK, { style: styles });
        case 'CL':
            return _jsx(CL, { style: styles });
        case 'CM':
            return _jsx(CM, { style: styles });
        case 'CN':
            return _jsx(CN, { style: styles });
        case 'CO':
            return _jsx(CO, { style: styles });
        case 'CR':
            return _jsx(CR, { style: styles });
        case 'CU':
            return _jsx(CU, { style: styles });
        case 'CV':
            return _jsx(CV, { style: styles });
        case 'CW':
            return _jsx(CW, { style: styles });
        case 'CX':
            return _jsx(CX, { style: styles });
        case 'CY':
            return _jsx(CY, { style: styles });
        case 'CZ':
            return _jsx(CZ, { style: styles });
        case 'DE':
            return _jsx(DE, { style: styles });
        case 'DJ':
            return _jsx(DJ, { style: styles });
        case 'DK':
            return _jsx(DK, { style: styles });
        case 'DM':
            return _jsx(DM, { style: styles });
        case 'DO':
            return _jsx(DO, { style: styles });
        case 'DZ':
            return _jsx(DZ, { style: styles });
        case 'EC':
            return _jsx(EC, { style: styles });
        case 'EE':
            return _jsx(EE, { style: styles });
        case 'EG':
            return _jsx(EG, { style: styles });
        case 'EH':
            return _jsx(EH, { style: styles });
        case 'ER':
            return _jsx(ER, { style: styles });
        case 'ES':
            return _jsx(ES, { style: styles });
        case 'ET':
            return _jsx(ET, { style: styles });
        case 'EU':
            return _jsx(EU, { style: styles });
        case 'FI':
            return _jsx(FI, { style: styles });
        case 'FJ':
            return _jsx(FJ, { style: styles });
        case 'FK':
            return _jsx(FK, { style: styles });
        case 'FM':
            return _jsx(FM, { style: styles });
        case 'FO':
            return _jsx(FO, { style: styles });
        case 'FR':
            return _jsx(FR, { style: styles });
        case 'GA':
            return _jsx(GA, { style: styles });
        case 'GB':
            return _jsx(GB, { style: styles });
        case 'GD':
            return _jsx(GD, { style: styles });
        case 'GE':
            return _jsx(GE, { style: styles });
        case 'GF':
            return _jsx(GF, { style: styles });
        case 'GG':
            return _jsx(GG, { style: styles });
        case 'GH':
            return _jsx(GH, { style: styles });
        case 'GI':
            return _jsx(GI, { style: styles });
        case 'GL':
            return _jsx(GL, { style: styles });
        case 'GM':
            return _jsx(GM, { style: styles });
        case 'GN':
            return _jsx(GN, { style: styles });
        case 'GP':
            return _jsx(GP, { style: styles });
        case 'GQ':
            return _jsx(GQ, { style: styles });
        case 'GR':
            return _jsx(GR, { style: styles });
        case 'GS':
            return _jsx(GS, { style: styles });
        case 'GT':
            return _jsx(GT, { style: styles });
        case 'GU':
            return _jsx(GU, { style: styles });
        case 'GW':
            return _jsx(GW, { style: styles });
        case 'GY':
            return _jsx(GY, { style: styles });
        case 'HK':
            return _jsx(HK, { style: styles });
        case 'HM':
            return _jsx(HM, { style: styles });
        case 'HN':
            return _jsx(HN, { style: styles });
        case 'HR':
            return _jsx(HR, { style: styles });
        case 'HT':
            return _jsx(HT, { style: styles });
        case 'HU':
            return _jsx(HU, { style: styles });
        case 'IC':
            return _jsx(IC, { style: styles });
        case 'ID':
            return _jsx(ID, { style: styles });
        case 'IE':
            return _jsx(IE, { style: styles });
        case 'IL':
            return _jsx(IL, { style: styles });
        case 'IM':
            return _jsx(IM, { style: styles });
        case 'IN':
            return _jsx(IN, { style: styles });
        case 'IO':
            return _jsx(IO, { style: styles });
        case 'IQ':
            return _jsx(IQ, { style: styles });
        case 'IR':
            return _jsx(IR, { style: styles });
        case 'IS':
            return _jsx(IS, { style: styles });
        case 'IT':
            return _jsx(IT, { style: styles });
        case 'JE':
            return _jsx(JE, { style: styles });
        case 'JM':
            return _jsx(JM, { style: styles });
        case 'JO':
            return _jsx(JO, { style: styles });
        case 'JP':
            return _jsx(JP, { style: styles });
        case 'KE':
            return _jsx(KE, { style: styles });
        case 'KG':
            return _jsx(KG, { style: styles });
        case 'KH':
            return _jsx(KH, { style: styles });
        case 'KI':
            return _jsx(KI, { style: styles });
        case 'KM':
            return _jsx(KM, { style: styles });
        case 'KN':
            return _jsx(KN, { style: styles });
        case 'KP':
            return _jsx(KP, { style: styles });
        case 'KR':
            return _jsx(KR, { style: styles });
        case 'KW':
            return _jsx(KW, { style: styles });
        case 'KY':
            return _jsx(KY, { style: styles });
        case 'KZ':
            return _jsx(KZ, { style: styles });
        case 'LA':
            return _jsx(LA, { style: styles });
        case 'LB':
            return _jsx(LB, { style: styles });
        case 'LC':
            return _jsx(LC, { style: styles });
        case 'LI':
            return _jsx(LI, { style: styles });
        case 'LK':
            return _jsx(LK, { style: styles });
        case 'LR':
            return _jsx(LR, { style: styles });
        case 'LS':
            return _jsx(LS, { style: styles });
        case 'LT':
            return _jsx(LT, { style: styles });
        case 'LU':
            return _jsx(LU, { style: styles });
        case 'LV':
            return _jsx(LV, { style: styles });
        case 'LY':
            return _jsx(LY, { style: styles });
        case 'MA':
            return _jsx(MA, { style: styles });
        case 'MC':
            return _jsx(MC, { style: styles });
        case 'MD':
            return _jsx(MD, { style: styles });
        case 'ME':
            return _jsx(ME, { style: styles });
        case 'MF':
            return _jsx(MF, { style: styles });
        case 'MG':
            return _jsx(MG, { style: styles });
        case 'MH':
            return _jsx(MH, { style: styles });
        case 'MK':
            return _jsx(MK, { style: styles });
        case 'ML':
            return _jsx(ML, { style: styles });
        case 'MM':
            return _jsx(MM, { style: styles });
        case 'MN':
            return _jsx(MN, { style: styles });
        case 'MO':
            return _jsx(MO, { style: styles });
        case 'MP':
            return _jsx(MP, { style: styles });
        case 'MQ':
            return _jsx(MQ, { style: styles });
        case 'MR':
            return _jsx(MR, { style: styles });
        case 'MS':
            return _jsx(MS, { style: styles });
        case 'MT':
            return _jsx(MT, { style: styles });
        case 'MU':
            return _jsx(MU, { style: styles });
        case 'MV':
            return _jsx(MV, { style: styles });
        case 'MW':
            return _jsx(MW, { style: styles });
        case 'MX':
            return _jsx(MX, { style: styles });
        case 'MY':
            return _jsx(MY, { style: styles });
        case 'MZ':
            return _jsx(MZ, { style: styles });
        case 'NA':
            return _jsx(NA, { style: styles });
        case 'NC':
            return _jsx(NC, { style: styles });
        case 'NE':
            return _jsx(NE, { style: styles });
        case 'NF':
            return _jsx(NF, { style: styles });
        case 'NG':
            return _jsx(NG, { style: styles });
        case 'NI':
            return _jsx(NI, { style: styles });
        case 'NL':
            return _jsx(NL, { style: styles });
        case 'NO':
            return _jsx(NO, { style: styles });
        case 'NP':
            return _jsx(NP, { style: styles });
        case 'NR':
            return _jsx(NR, { style: styles });
        case 'NU':
            return _jsx(NU, { style: styles });
        case 'NZ':
            return _jsx(NZ, { style: styles });
        case 'OM':
            return _jsx(OM, { style: styles });
        case 'PA':
            return _jsx(PA, { style: styles });
        case 'PE':
            return _jsx(PE, { style: styles });
        case 'PF':
            return _jsx(PF, { style: styles });
        case 'PG':
            return _jsx(PG, { style: styles });
        case 'PH':
            return _jsx(PH, { style: styles });
        case 'PK':
            return _jsx(PK, { style: styles });
        case 'PL':
            return _jsx(PL, { style: styles });
        case 'PM':
            return _jsx(PM, { style: styles });
        case 'PN':
            return _jsx(PN, { style: styles });
        case 'PR':
            return _jsx(PR, { style: styles });
        case 'PS':
            return _jsx(PS, { style: styles });
        case 'PT':
            return _jsx(PT, { style: styles });
        case 'PW':
            return _jsx(PW, { style: styles });
        case 'PY':
            return _jsx(PY, { style: styles });
        case 'QA':
            return _jsx(QA, { style: styles });
        case 'RE':
            return _jsx(RE, { style: styles });
        case 'RO':
            return _jsx(RO, { style: styles });
        case 'RS':
            return _jsx(RS, { style: styles });
        case 'RU':
            return _jsx(RU, { style: styles });
        case 'RW':
            return _jsx(RW, { style: styles });
        case 'SA':
            return _jsx(SA, { style: styles });
        case 'SB':
            return _jsx(SB, { style: styles });
        case 'SC':
            return _jsx(SC, { style: styles });
        case 'SD':
            return _jsx(SD, { style: styles });
        case 'SE':
            return _jsx(SE, { style: styles });
        case 'SG':
            return _jsx(SG, { style: styles });
        case 'SH':
            return _jsx(SH, { style: styles });
        case 'SI':
            return _jsx(SI, { style: styles });
        case 'SJ':
            return _jsx(SJ, { style: styles });
        case 'SK':
            return _jsx(SK, { style: styles });
        case 'SL':
            return _jsx(SL, { style: styles });
        case 'SM':
            return _jsx(SM, { style: styles });
        case 'SN':
            return _jsx(SN, { style: styles });
        case 'SO':
            return _jsx(SO, { style: styles });
        case 'SR':
            return _jsx(SR, { style: styles });
        case 'SS':
            return _jsx(SS, { style: styles });
        case 'ST':
            return _jsx(ST, { style: styles });
        case 'SV':
            return _jsx(SV, { style: styles });
        case 'SX':
            return _jsx(SX, { style: styles });
        case 'SY':
            return _jsx(SY, { style: styles });
        case 'SZ':
            return _jsx(SZ, { style: styles });
        case 'TA':
            return _jsx(TA, { style: styles });
        case 'TC':
            return _jsx(TC, { style: styles });
        case 'TD':
            return _jsx(TD, { style: styles });
        case 'TF':
            return _jsx(TF, { style: styles });
        case 'TG':
            return _jsx(TG, { style: styles });
        case 'TH':
            return _jsx(TH, { style: styles });
        case 'TJ':
            return _jsx(TJ, { style: styles });
        case 'TK':
            return _jsx(TK, { style: styles });
        case 'TL':
            return _jsx(TL, { style: styles });
        case 'TM':
            return _jsx(TM, { style: styles });
        case 'TN':
            return _jsx(TN, { style: styles });
        case 'TO':
            return _jsx(TO, { style: styles });
        case 'TR':
            return _jsx(TR, { style: styles });
        case 'TT':
            return _jsx(TT, { style: styles });
        case 'TV':
            return _jsx(TV, { style: styles });
        case 'TW':
            return _jsx(TW, { style: styles });
        case 'TZ':
            return _jsx(TZ, { style: styles });
        case 'UA':
            return _jsx(UA, { style: styles });
        case 'UG':
            return _jsx(UG, { style: styles });
        case 'UM':
            return _jsx(UM, { style: styles });
        case 'US':
            return flagsWithoutWrapper ? (_jsx("div", { style: { height: '75px' }, children: _jsx(USFlag, { styles: styles }) })) : (_jsx(USFlag, { styles: styles }));
        case 'UY':
            return _jsx(UY, { style: styles });
        case 'UZ':
            return _jsx(UZ, { style: styles });
        case 'VA':
            return _jsx(VA, { style: styles });
        case 'VC':
            return _jsx(VC, { style: styles });
        case 'VE':
            return _jsx(VE, { style: styles });
        case 'VG':
            return _jsx(VG, { style: styles });
        case 'VI':
            return _jsx(VI, { style: styles });
        case 'VN':
            return _jsx(VN, { style: styles });
        case 'VU':
            return _jsx(VU, { style: styles });
        case 'WF':
            return _jsx(WF, { style: styles });
        case 'WS':
            return _jsx(WS, { style: styles });
        case 'XK':
            return _jsx(XK, { style: styles });
        case 'YE':
            return _jsx(YE, { style: styles });
        case 'YT':
            return _jsx(YT, { style: styles });
        case 'ZA':
            return _jsx(ZA, { style: styles });
        case 'ZM':
            return _jsx(ZM, { style: styles });
        case 'ZW':
            return _jsx(ZW, { style: styles });
        default:
            return _jsx(EU, { style: styles });
    }
};
export default CountryFlagDropdown;
