// ----------------------------------------------------------------------
var EntityIdHeader = 'Entity-Id';
var VersionIdHeader = 'Version-Id';
var VersionTimestampHeader = 'Version-Timestamp';
var ReleaseTimestampHeader = 'Release-Timestamp';
export var LegalProvisionIdHeader = 'Legal-Provision-Id';
function paths(root, sublink, parameters) {
    return "".concat(root).concat(sublink).concat(parameters);
}
function path(root, sublink) {
    return "".concat(root).concat(sublink);
}
function buildContextParameters(legalProvisionContext) {
    var _a;
    var _b, _c;
    var params = (_a = {},
        _a[EntityIdHeader] = legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.entityId,
        _a[VersionIdHeader] = legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.versionId,
        _a[VersionTimestampHeader] = (_b = legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.versionTimestamp) === null || _b === void 0 ? void 0 : _b.toISOString(),
        _a[ReleaseTimestampHeader] = (_c = legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.releaseTimestamp) === null || _c === void 0 ? void 0 : _c.toISOString(),
        // it's required for the edit document element page to work
        _a[LegalProvisionIdHeader] = legalProvisionContext === null || legalProvisionContext === void 0 ? void 0 : legalProvisionContext.id,
        _a);
    var searchParams = new URLSearchParams();
    Object.entries(params).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (value) {
            searchParams.append(key, value);
        }
    });
    return "?".concat(searchParams.toString());
    // return `?${EntityIdHeader}=${legalProvisionContext?.entityId}&${VersionIdHeader}=${
    //   legalProvisionContext?.versionId
    // }&${VersionTimestampHeader}=${legalProvisionContext?.versionTimestamp?.toISOString()}&${ReleaseTimestampHeader}=${legalProvisionContext?.releaseTimestamp?.toISOString()}`;
}
// ----------------------------------------------------------------------
export var ROOTS_DASHBOARD = '/dashboard';
export var PATH_AUTH = {
    login: '/login',
};
export var PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    overview: {
        root: path(ROOTS_DASHBOARD, '/overview'),
    },
    legalProvision: {
        root: path(ROOTS_DASHBOARD, '/legal-provision'),
        details: function (id, legalProvisionContext, queryHash) {
            if (queryHash === void 0) { queryHash = null; }
            var hash = queryHash ? "#".concat(queryHash) : '';
            return paths(ROOTS_DASHBOARD, "/legal-provision/".concat(id, "/details").concat(hash), buildContextParameters(legalProvisionContext));
        },
        new: path(ROOTS_DASHBOARD, '/legal-provision/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/legal-provision/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
        newVersion: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/legal-provision/".concat(id, "/new"), buildContextParameters(legalProvisionContext));
        },
        manageAccess: function (entityId, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/legal-provision/".concat(entityId, "/manage-access"), buildContextParameters(legalProvisionContext));
        },
    },
    documentElement: {
        new: path(ROOTS_DASHBOARD, '/document-element/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/document-element/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
    },
    sentenceFragments: {
        root: function (id) { return path(ROOTS_DASHBOARD, "/sentence-fragments/".concat(id)); },
    },
    legalRequirement: {
        root: path(ROOTS_DASHBOARD, '/legal-requirement'),
        new: path(ROOTS_DASHBOARD, '/legal-requirement/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/legal-requirement/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
    },
    property: {
        root: path(ROOTS_DASHBOARD, '/property'),
        new: path(ROOTS_DASHBOARD, '/property/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/property/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
    },
    radar: {
        root: path(ROOTS_DASHBOARD, '/legal-radar'),
    },
    technicalRequirement: {
        root: path(ROOTS_DASHBOARD, '/technical-requirement'),
        new: path(ROOTS_DASHBOARD, '/technical-requirement/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/technical-requirement/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
    },
    input: {
        root: path(ROOTS_DASHBOARD, '/input'),
        new: path(ROOTS_DASHBOARD, '/input/new'),
        edit: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/input/".concat(id, "/edit"), buildContextParameters(legalProvisionContext));
        },
    },
    tagGroup: {
        root: path(ROOTS_DASHBOARD, '/tag-group'),
        new: path(ROOTS_DASHBOARD, '/tag-group/new'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/tag-group/".concat(id, "/edit")); },
    },
    tag: {
        root: path(ROOTS_DASHBOARD, '/tag'),
        new: path(ROOTS_DASHBOARD, '/tag/new'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/tag/".concat(id, "/edit")); },
    },
    release: {
        new: function (id, legalProvisionContext) {
            return paths(ROOTS_DASHBOARD, "/release/".concat(id, "/new"), buildContextParameters(legalProvisionContext));
        },
    },
    user: path(ROOTS_DASHBOARD, '/user/account'),
    delta: path(ROOTS_DASHBOARD, '/delta'),
    exports: {
        list: path(ROOTS_DASHBOARD, '/exports/list'),
        new: path(ROOTS_DASHBOARD, '/exports/new'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/exports/".concat(id, "/edit")); },
    },
    reports: {
        list: path(ROOTS_DASHBOARD, '/reports/list'),
    },
    import: path(ROOTS_DASHBOARD, '/import'),
    alerts: {
        list: path(ROOTS_DASHBOARD, '/alerts/list'),
        new: path(ROOTS_DASHBOARD, '/alerts/new'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/alerts/".concat(id, "/edit")); },
    },
};
