export var ICON_NAME = {
    downloads: 'eva:download-fill',
    gear: 'eva:settings-2-fill',
    eye: 'clarity:eye-solid',
    tags: 'mdi:tag-multiple',
    alerts: 'eva:bell-fill',
    reports: 'iconoir:reports-solid',
    import: 'uil:import',
    tag: 'mdi:tag-edit',
    export: 'uil:export',
    requirement: 'iconoir:page-star',
    input: 'iconoir:input-field',
    legal: 'icomoon-free:section',
    legalRequirement: 'iconoir:page-edit',
    blog: 'ic_blog',
    delta: 'material-symbols:difference',
    history: 'eva:clock-fill',
    integration: 'oui:integration-general',
    radar: 'material-symbols-light:radar',
};
