import axios from 'axios';
// config
import { BACKEND_URL } from '../shared-config-global';
// ----------------------------------------------------------------------
var axiosInstance = axios.create({
    headers: {
        common: {
            'Access-Control-Expose-Headers': 'content-disposition',
        },
    },
    baseURL: BACKEND_URL
});
axiosInstance.interceptors.response.use(function (response) { return response; }, function (error) { return Promise.reject((error.response && error.response.data) || 'Something went wrong'); });
export default axiosInstance;
